import { inject, Injectable } from '@angular/core';
import { TranslateParams, TranslocoService } from '@jsverse/transloco';
type HesTranslateParam = string;
type HashMap = { [key: string]: any };
@Injectable({
  providedIn: 'root',
})
export class HesTranslateService {
  private transloco = inject(TranslocoService);

  t(key: HesTranslateParam, params?: HashMap, lang?: string): string {
    return this.transloco.translate(key, params, lang);
  }

  enumT(key: HesTranslateParam, params?: HashMap, lang?: string): string {
    return this.transloco.translate('enum.' + key?.toUpperCase(), params, lang);
  }
}
